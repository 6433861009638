import React, { useState } from "react";
import "./Order.scss";
import { Button, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import TableFormGenerator from "./TableFormGenerator";
import { deleteFromLocal, saveToLocal } from '../../common/localStorage.service';

const OrderEntry = () => {
  const data = [{ key: 1 }];
  const [rowdata, setRowData] = useState(data);

  const addDataHandler = () => {
    const updatedTabMenu = [...rowdata, { key: rowdata.length + 1 }];
    setRowData(updatedTabMenu);
  };

  const handleOnSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {


    // check allocation sums is equal to 100
    let sum = values.subRow.reduce((a, b) => {
      return a + parseInt(b["allocation"]);
    }, 0);
    if (sum !== 100) {
      values.subRow.forEach((_, i) => {
        setFieldError(`subRow.${i}.allocation`, "error");
      })
      setSubmitting(false);
      return;
    }

    // check if combinaion os fund , strategy and sub-strategy is smilar ?

    let combination_map = {};
    let invalid_index = []
    values.subRow.forEach((data_, index) => {
      if (!combination_map[`${data_.fund}-${data_.strategy}-${data_.subStrategy}`]) {
        combination_map[`${data_.fund}-${data_.strategy}-${data_.subStrategy}`] = [];
      } else if (combination_map[`${data_.fund}-${data_.strategy}-${data_.subStrategy}`].length > 0) {
        invalid_index.push(index)
      }
      combination_map[`${data_.fund}-${data_.strategy}-${data_.subStrategy}`].push(index);
    });

    if(invalid_index.length > 0){
      invalid_index.forEach(i=>{
        setFieldError(`subRow.${i}.fund`, "error");
        setFieldError(`subRow.${i}.strategy`, "error");
        setFieldError(`subRow.${i}.substrategy`, "error");
        
      })
      setSubmitting(false);
      return;
    }


    setSubmitting(true);
    setTimeout(() => {
      saveToLocal({ values, collectionName: "orders", collectionId: "order_id" });
      resetForm();
      setSubmitting(false);
    }, 1000)
  };

  const onDeleteData = (e) => {
    if (e < 1) {
      return;
    }
    let newarray = rowdata.slice()
    newarray.splice(e, 1)
    setRowData(newarray);
  };
  return (
    <div className="panel panel-default mb-2 h-100 ">
      <div className="panel-body paneldecoration pb-0 h-100">
        <div className="row ">
          <div className="col-md-12 mb-2 d-flex align-items-center justify-content-between">
            <div className="table-title mb-0 ml-1"> Order Entry</div>
            <div className="pull-right table-panel-header ">
              <Button
                className="mr-1"
                size="sm"
                variant="secondary"
                type="submit"
              >
                Summary
              </Button>
              <Button
                className="mr-1"
                size="sm"
                variant="secondary"
                type="submit"
                onClick={() => addDataHandler()}
              >
                Add New
              </Button>
            </div>
          </div>
          <div className="col-md-12 mb-3 h-100">
            {rowdata.map((e, i) => (
              <div key={e.key} className="table-responsive mb-3">
                <TableFormGenerator index={i} deleteDataHandler={() => onDeleteData(i)} handleOnSubmit={handleOnSubmit} onReset={e => onDeleteData(i)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderEntry;

import React from 'react'

const DataTableHead = ({ headerGroups, headerConfig }) => {
    return (
        <thead className=" font-weight-bold">
            {headerGroups.map((headerGroup, header_key) => (
                <tr className="w-100" {...headerGroup.getHeaderGroupProps()} key={header_key}>
                    {headerGroup.headers.map((column, column_key) => (
                        <th
                            scope="row"
                            key={column_key}
                            className={`${Array.isArray(headerConfig?.classes) ? headerConfig?.classes?.join(" ") : ""} `
                            }
                            {...column.getHeaderProps()}
                        >
                            {column.render('Header')}

                            <div
                                {...column.getResizerProps()}
                                // className={`resizer ${column.isResizing ? 'isResizing' : ''
                                //     }`}
                            />
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    )
}

export default DataTableHead

import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { equityTableData as tableData } from "../../../utilities/tablefields";
import Button from "react-bootstrap/Button";
import httpService from "../../../common/http.service";

import DataTable from '../../../components/HigherOrderComponent/DataTable/DataTable';
const EquityTable = (props) => {
  const { model } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const viewModal = () => {
    props.viewTableModal("EquityTable");
  };


  useEffect(async () => {
    setLoading(true);
    
    let data = [
      {
        "ticker": "JPM",
        "name": "JPMorgan Chase & Co.�",
        "change": -0.41,
        "price": 163.79,
        "bid": 163.88,
        "ask": 163.92,
        "low": 163.6718,
        "high": 164.85,
        "open": 164.39,
        "prev": 164.47
      },
      {
        "ticker": "MS",
        "name": "Morgan Stanley",
        "change": -0.19,
        "price": 97.49,
        "bid": 97.79,
        "ask": 97.85,
        "low": 97.28,
        "high": 98.25,
        "open": 97.93,
        "prev": 97.68
      },
      {
        "ticker": "BAC",
        "name": "Bank of America Corporation",
        "change": 0.05,
        "price": 46.52,
        "bid": 46.48,
        "ask": 46.49,
        "low": 46.42,
        "high": 46.8,
        "open": 46.71,
        "prev": 46.51
      },
      {
        "ticker": "GS",
        "name": "The Goldman Sachs Group, Inc.",
        "change": -0.68,
        "price": 388.93,
        "bid": 391.18,
        "ask": 391.12,
        "low": 388.55,
        "high": 393.9,
        "open": 392.3,
        "prev": 391.55
      }
    ]
    setData(data);

    setLoading(false);

  }, []);


  const config = {
    tableCard: {
      classes: ["h-inherit"]
    },
    tableWrapper: {
      classes: ["h-100"]
    },
  }

  return (
    <div className="card panel panel-default  my-2 h-100">
      <div className="card-body px-2 py-2 h-100">
        <div className="row">
          <div className="col-md-12">
            <div className="table-title">Equity</div>
            <div className="pull-right table-panel-header">
              <div className="lastupdate">Last Update :</div>
              <span className="lastupdateHead">00:00:00</span>
              {model && (
                <Button
                  onClick={() => viewModal()}
                  variant="default"
                  className="color-white button-padding"
                >
                  <i className="fa fa-window-maximize"></i>
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="row h-90">
          <div className="col-md-12 h-100">
            <DataTable
              pagination={true}
              columnData={tableData}
              config={config}
              loading={loading}
              data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquityTable;

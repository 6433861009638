import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import TradeEntry from "./TradeEntry";
import Watchlist from "./Watchlist";
import Snapshot from "./Snapshot";
import TabContent from "../../components/HigherOrderComponent/TabContent";
import DataTable from "../../components/HigherOrderComponent/DataTable/DataTable";
import MultiActionBtn from "../../components/HigherOrderComponent/Button/MultiActionBtn";
import { useHistory } from "react-router-dom";
import { setLSHandler, getLSHandler } from "../../common/localStorage.service";
import styles from './Trade.module.scss';

const Trade = () => {
  const history = useHistory();
  const [tradeIds, setTradeIds] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const data = [
    {
      order_id: 1,
      instrument_type: "instrument",
      order_date: "17/06/2022",
      buy_sell: "Buy",
      trade_date: "14/06/2018",
      settle_date: "settle_date",
      ticker: "ticker",
      name: "name",
      notional: "12345678",
      price: "price",
      ccy: 123,
      risk_group: "risk_group",
      comments: "comments",
    },
    {
      order_id: 1,
      instrument_type: "instrument",
      order_date: "17/06/2022",
      buy_sell: "Buy",
      trade_date: "14/06/2018",
      settle_date: "settle_date",
      ticker: "ticker",
      name: "name",
      notional: "12345678",
      price: "price",
      ccy: 123,
      risk_group: "risk_group",
      comments: "comments",
    },
  ];
  const config = {
    tableCard: {
      classes: ["h-inherit"],
    },
    tableWrapper: {
      classes: ["h-100"],
    },
  };
  const columnData = [
    {
      Header: "Order Id",
      accessor: "order_id",
      width: 18,
    },
    {
      Header: "Instrument Type",
      accessor: "instrument_type",
      width: 40,
    },
    {
      Header: "Order Date",
      accessor: "order_date",
      width: 30,
    },
    {
      Header: "Buy / Sell",
      accessor: "buy_sell",
      width: 20,
    },
    {
      Header: "Trade Date",
      accessor: "trade_date",
      width: 30,
    },
    {
      Header: "Settle Date",
      accessor: "settle_date",
      width: 25,
    },
    {
      Header: "Ticker",
      accessor: "ticker",
      width: 20,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 40,
    },
    {
      Header: "Notional",
      accessor: "notional",
      width: 25,
    },
    {
      Header: "Price",
      accessor: "price",
      width: 20,
    },
    {
      Header: "CCY",
      accessor: "ccy",
      width: 15,
    },
    {
      Header: "Risk Group",
      accessor: "risk_group",
      width: 30,
    },
    {
      Header: "Comments",
      accessor: "comments",
      width: 60,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 30,
      Cell: (cellObj) => (
        <>
          <span className="badge badge-pill badge-success mr-1">Flagged</span>
        </>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      width: 48,
      Cell: (cellObj) => (
        <>
          <MultiActionBtn
            className="mr-3"
            options={["#1234", "#2345", "#3456"]}
            variant="success"
            size="sm"
            label="Save"
            onClick={(e) => navigateToTrade(e)}
          />
          <MultiActionBtn variant="danger" size="sm" label="Delete" />
        </>
      ),
    },
  ];

  useEffect(() => {
    let savedData = getLSHandler("Trade");
    let data =
      savedData && savedData.tradeIds.length > 0
        ? savedData.tradeIds
        : tradeIds;
    setTradeIds(data);
 
    if (history.location && history.location.hash && history.location.search) {
      const existIndex = data.findIndex(
        (element) => element.title === history.location.hash
      );
      let updatedTradeMenu = [];
      if (existIndex === -1) {
        updatedTradeMenu = [...data, { title: history.location.hash }];
      } else {
        updatedTradeMenu = [...data];
      }

      setTradeIds(updatedTradeMenu);
      setLSHandler("Trade", {
        tradeIds: updatedTradeMenu,
        activatedTab: history.location.hash,
      });

      const findIndex = updatedTradeMenu.findIndex(
        (element) => element.title === history.location.hash
      );

      if (findIndex !== -1) {
        setTabIndex(findIndex);
      }
    } else if (
      (history.location &&
        history.location.search === "" &&
        history.location.hash === "") ||
      (history.location &&
        history.location.search &&
        history.location.hash === "")
    ) {
      let savedData = getLSHandler("Trade");

      if(savedData && savedData.tradeIds.length > 0 ){
      const findIndex =  savedData.tradeIds.findIndex(
        (element) => element.title === savedData.activatedTab
      );

      if (findIndex !== -1) {
        setTabIndex(findIndex);
      }

    }
  }
  }, []);

  const navigateToTrade = (e) => {
    history.push({
      pathname: "/trade",
      search: `?id=${e}`,
    });

    const updatedTradeMenu = [...tradeIds, { title: e }];

    setTradeIds(updatedTradeMenu);
    setLSHandler("Trade", {
      tradeIds: updatedTradeMenu,
      activatedTab: e,
    });

    const findIndex = updatedTradeMenu.findIndex(
      (element) => element.title === e
    );

    if (findIndex !== -1) {
      setTabIndex(findIndex);
    }
  };

  const tabHandler = (i, title) => {
    setTabIndex(i);
    history.push({
      pathname: "/trade",
      search: `?id=${title}`,
    });

    setLSHandler("Trade", { tradeIds, activatedTab: title });
  };
  const Orders = () => {
    return (
      <div className="row row-tables blotter-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
                <div className="col-md-12">
                  <DataTable
                    pagination={true}
                    config={config}
                    columnData={columnData}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Trades = () => {
    return (
      <div className="row h-100 row-tables blotter-tables">
        <div className="col-md-12 h-100 ">
          <div className="panel panel-default h-100">
            <div className="panel-body paneldecoration h-100">
              <div className="row">
                <div className="col-md-12">
                  <DataTable
                    pagination={true}
                    config={config}
                    columnData={columnData}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Quotes = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
                <div className="col-md-12">
                  <span>Quotes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Positions = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
                <div className="col-md-12">
                  <span>Positions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const tabMenu = [
    {
      title: "Orders",
      component: <Orders />,
    },
    {
      title: "Trades",
      component: <Trades />,
    },
    {
      title: "Quotes",
      component: <Quotes />,
    },
    {
      title: "Positions",
      component: <Positions />,
    },
  ];

  return (
    <div className="row m-0 h-100">
      <div className="col-lg-12 col-md-12 p-0 h-100">

      {tradeIds.length > 0 &&  

    <div className={`${styles.tradeParentRow} row  m-1 mb-5`}>
         <div className="col-lg-12 col-md-12">
         <div className="row">
           {tradeIds.map((item, i) => {
             return (
               <div key={i} className="col-md-1 col-lg-1 col-1 p-0">
                 <Nav.Item>
                   <Nav.Link
                     onClick={() => tabHandler(i, item.title)}
                     eventKey={`link-${i}`}
                     className={tabIndex === i ? "active" : ""}
                   >
                     {item.title}
                   </Nav.Link>
                 </Nav.Item>
               </div>
             );
           })}
         </div>
       </div> 
     
      <div className="col-lg-12 tab-container p-0">
        <div className="tab-content tab-content-custom h-100 p-0">
          <div className="row m-0 h-100">
            <div className={`${styles.watchListCol} col-xl-3  col-lg-3 col-md-3 col-sm-12 col-12 h-100`}>
              <div className="content-border h-100 view-details">
                <Watchlist />
              </div>
            </div>

            <div className={`${styles.snapshotCol} col-xl-6  col-lg-6 col-md-6 col-sm-12 col-12 px-0 h-100`} >
              <div className="content-border h-100">
                <Snapshot />
              </div>
            </div>

            <div className={`${styles.watchListCol} col-xl-3  col-lg-3 col-md-3 col-sm-12 col-12 h-100`}>
              <div className="content-border h-100">
                <TradeEntry />
              </div>
            </div>

           
          </div>
        </div>
      </div>
    </div>
}
     <div className={`${styles.tradeChildRow} row m-0 border-0`}>
      
     <div className="col-lg-12 tab-container border-0 p-0">
             <TabContent padding={"p-0"} tabMenu={tabMenu} />
     </div>
   </div>
   </div>
   </div>
  );
};

export default Trade;

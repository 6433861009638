import React from 'react'

export default function DataTableRow({ page, prepareRow, getTableBodyProps, visibleColumns, rowConfig, children, renderRowSubComponent }) {

    return (
        <tbody {...getTableBodyProps()}>
            {page.map((row, row_key) => {
                prepareRow(row)
                return (
                    <React.Fragment key={row_key}   >
                        <tr className="w-100" {...row.getRowProps()}
                        >
                            {row.cells.map((cell, cell_key) => {
                                return (
                                    <td
                                        key={cell_key}
                                        {...cell.getCellProps()}
                                        className={` ${Array.isArray(rowConfig?.classes) ? rowConfig?.classes?.join(" ") : ""}`}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                        {row.isExpanded ? (
                            <tr className="w-100" {...row.getRowProps({ ...{ key: Math.random() } })} >
                                <td colSpan={visibleColumns.length} {...row?.cells[row.index]?.getCellProps?.({...{ key: Math.random() }})}>
                                    {renderRowSubComponent({ row })}
                                </td>
                            </tr>
                        ) : null}
                    </React.Fragment>
                )
            })}
        </tbody>
    )
}

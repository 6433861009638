import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../../components/Layout/Dashboard.scss";
import PortfolioSummary from "./PortfolioSummary";
import MarketSummary from "./MarketSummary/MarketSummary";
import SentimentSummary from "./SentimentSummary/SentimentSummary";
import FXDashboard from "./FXDashboard";
import CryptoDashboard from "./CryptoDashboard";
import EquityDashboard from "./EquityDashboard";
import { BrowserRouter as Router, Link, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Dashboard = (props) => {
  const data = [
    {
      title: "Portfolio Summary",
      link: "/portfolio",
    },
    {
      title: "Market Summary",
      link: "/market",
    },
    {
      title: "Sentiment Summary",
      link: "/sentiment",
    },
    {
      title: "Crypto Dashboard",
      link: "/crypto",
    },
  ];

  const otherLinks = [
    { label: "FX Dashboard", value: 4, link: "/fx" },
    { label: "Equity Dashboard", value: 5, link: "/equity" },
  ];

  const tabContents = [
    {path:'/dashboard/portfolio' , components:<PortfolioSummary path="/dashboard/portfolio" />},
    {path:'/dashboard/market' , components:<MarketSummary path="/dashboard/market" />},
    {path:'/dashboard/sentiment' , components:<SentimentSummary path="/dashboard/sentiment" />},
    {path:'/dashboard/fx' , components:<FXDashboard path="/dashboard/fx" />},
    {path:'/dashboard/equity' , components:<EquityDashboard path="/dashboard/equity" />},
    {path:'/dashboard/crypto' , components: <CryptoDashboard path="/dashboard/crypto" />},
  ]
  const { url, path } = useRouteMatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [tabMenu, setTabMenu] = useState(data);
  const [otherMenu, setOtherMenu] = useState(otherLinks);
  const history = useHistory();

  const onOtherSelect = (e) => {
    const findIndex = otherMenu.findIndex(
      (element) => element.value === Number(e)
    );
    if (findIndex !== -1) {
      const updatedTabMenu = [
        ...tabMenu,
        {
          title: otherMenu[findIndex].label,
          link: otherMenu[findIndex].link,
        },
      ]
      setTabMenu(updatedTabMenu);
      setTabIndex(tabMenu.length);
      history.push(`${url}${otherMenu[findIndex].link}`);
      otherMenu.splice(findIndex, 1);
      setOtherMenu(otherMenu);
      setLocalStorageDataHandler(updatedTabMenu,'/' + window.location.pathname.split('/')[2] , otherMenu)
    }
  };
 
  const setLocalStorageDataHandler = (updatedTabMenu , url , menu) => {
    localStorage.setItem(
      "Dashboard",
      JSON.stringify({ tabMenu: updatedTabMenu, otherMenu: menu , url})
    );
  }
  useEffect(() => {
    let option = JSON.parse(localStorage.getItem("Dashboard"));
    if (option && Object.keys(option).length > 1) {
      if(window.location.pathname.includes(option.url) || window.location.pathname === '/dashboard' ){
        history.push(`${url}${option.url}`);
      } 

      setTabMenu(option.tabMenu);
      setOtherMenu(option.otherMenu);

      option.tabMenu && option.tabMenu.length > 0 && option.tabMenu.forEach((e, i) => {
        if (window.location.pathname.includes(e.link)) {
          setTabIndex(i);
        }
      });

    setLocalStorageDataHandler(  option.tabMenu ,'/' + window.location.pathname.split('/')[2] , option.otherMenu)

    }else{
      history.push(`${url}/crypto`);
      tabMenu.forEach((e, i) => {
        if (window.location.pathname.includes(e.link)) {
          setTabIndex(i);
        }
      });
    }

  }, []);

  const getActivatedNav = (path) => {
    return window.location.pathname === path;
  };

  return (
    <Router>
      <div className="row dashboard main-menu m-0 p-0">
        <div className="d-flex align-items-center justify-content-start main-tab-wrapper">
          <div className="p-0 main-tab">
            <Nav className="bond-menu dashboard-menu row  d-flex align-items-center" fill variant="tabs">
              {tabMenu && tabMenu.length && tabMenu.map((_, index) => {
                return (
                  <div key={index}  className="p-0">
                    <Nav.Item key={index}>
                      <Nav.Link
                        as={Link}
                        onClick={() => {setTabIndex(index) , setLocalStorageDataHandler(tabMenu , _.link , otherMenu)}}
                        to={`${url}${_.link}`}
                        eventKey={`link-${index}`}
                        className={tabIndex === index ? "active" : ""}
                      >
                        {_.title}
                      </Nav.Link>
                    </Nav.Item>
                  </div>
                );
              })}
          
            </Nav>
          </div>

          {otherMenu && otherMenu.length > 0 && (
            <div style={{width:'180px'}} className="p-0">
              <NavDropdown
                onSelect={(e) => onOtherSelect(e)}
                className="nav-dropdown-tab p-0"
                title="Other +"
                id="nav-dropdown-tab"
              >
                {otherMenu.map((item, i) => (
                  <NavDropdown.Item key={i} eventKey={item.value}>
                    {item.label}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </div>
          )}
        </div>
        <div className="col-lg-12 dashboard-tab-content  p-0">
          <div className="tab-content tab-content-custom h-100 p-3">
            <Router>  
            {tabContents.map((item, i) => (
                  getActivatedNav(item.path) && (
                    item.components
                  )
                  ))}
            </Router>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Dashboard;


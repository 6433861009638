import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
  SectorList,
  PortfolioList,
  EventList,
} from "../../../utilities/tablefields";
import TabContent from "../../../components/HigherOrderComponent/TabContent";
import LineChart from "../../../components/HigherOrderComponent/Chart/LineCart";
import { DataTable } from "../../../components/HigherOrderComponent/DataTable";
import { LineStyle } from "lightweight-charts";
import data from '../../../json/chart.json';

const EquityDetails = () => {

  const [chartData , setChartData] = useState(data.equityChart)
  let chatOptions = {
    leftPriceScale: {
      scaleMargins: {
        top: 0.2,
        bottom: 0.2,
      },
      visible: true,
      borderVisible: false,
    },
    layout: {
      backgroundColor: "#546575",
      textColor: "#ffffff",
    },
    rightPriceScale: {
      visible: false,
    },
    timeScale: {
      borderVisible: false,
    },
    grid: {
      vertLines: {
        color: "rgba(0, 0, 0, 0)",
        style: LineStyle.Dotted,
      },
      horzLines: {
        color: "rgba(197, 203, 206, 0.4)",
        style: LineStyle.Dotted,
      },
    },
  };

 

  const detailsPartTwo = [
    {label:"Revneue" , value:'58.27B'},
    {label:'EPS' , value:'7.83'},
    {label:'Operating Income' , value:'14.24B'},
    {label:'Operating Margin' , value:'39.84%'},
    {label:'Net Income' , value:'14.24B'},
    {label:'Net Profit Margin' , value:'25.27%'},
  ];
  const detailsPartThree = [
    "CEO",
    "Founded Date",
    "Headquarter",
    "Website",
    "Description",
  ];

  const sectorListData = [
    {
      sectory: "Financial Services",
      industry: "Capital Markets",
      name: "BAC",
      Issuer: "Bank of America Corporation (BAC)",
      ID: "BAC",
      PX_Last: "46.51",
      Open: "47.08",
      High: "47.20",
      Low: "46.45",
      Close: "46.51",
      PrevClose: "47.08",
      Volume: "32,927,300"
    },
    {
      sectory: "Financial Services",
      industry: "Capital Markets",
      name: "GS",
      Issuer: "The Goldman Sachs Group, Inc.",
      ID: "GS",
      PX_Last: "403.09",
      Open: "401.85",
      High: "402.65",
      Low: "391.47",
      Close: "391.55",
      PrevClose: "403.09",
      Volume: "2,922,700"
    }
  ];


  const columns = [
    {
      Header: () => <div className="d-none" />,
      accessor: "name",
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <i className="fa fa-chevron-down fa-xs mr-2"></i>
          ) : (
            <i className="fa fa-chevron-right fa-xs mr-2"></i>
          )}
         {row.values.name}
        </span>
      ),
    },
  ];

  const subComponent = (row) => {
    return (
      <div className="row m-0">
      <div className="col-md-12 expanded-table p-0">
        

        {row && row.row && row.row.values.name !== 'Custom' && 
        <DataTable
        columnData={SectorList}
        data={sectorListData}
        pagination={false}
      />
        }
        {row && row.row && row.row.values.name === 'Custom' && 
        <p className="text-center mb-0"> No Data</p>
        }
      </div>
      </div>
    );
  };

  const dataSet = [
    {
      id: "12345",
      name: "Sector",
    },
    {
      id: "678910",
      name: "Custom",
    },
  ];
 
  const List = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row security-list">
                <div className="col-md-12">
                <DataTable
            columnData={columns}
            data={dataSet}
            pagination={false}
            renderRowSubComponent={subComponent}
            />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Portfolio = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
                <div className="col-md-12">
                  <DataTable columnData={PortfolioList} data={[]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Event = () => {
    return (
      <div className="row row-tables">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body paneldecoration">
              <div className="row">
                <div className="col-md-12">
                  <DataTable columnData={EventList} data={[]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Analytics = () => {
    return <div>Analytics Works !</div>;
  };

  const tabMenu = [
    {
      title: "List",
      component: <List />,
    },
    {
      title: "Portfolio",
      component: <Portfolio />,
    },
    {
      title: "Analytics",
      component: <Analytics />,
    },
    {
      title: "Events",
      component: <Event />,
    },
  ];

  return (
    <div className="row m-1">
      <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 pr-0 ">
        <div className="content-border h-100 view-details">
          <div className="col-md-12 content-dotted-border col-lg-12">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">MS</Form.Label>
                <Form.Text className="text-muted">(ID/Ticker)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0"> 97.68</Form.Label>
                <Form.Text className="text-muted">(PX_Last)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">+0.32 (+0.33%)</Form.Label>
                <Form.Text className="text-muted">(+/- vs ytdy)</Form.Text>

              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">At close: 4:03PM EST</Form.Label>
                <Form.Text className="text-muted">(Timestamp)</Form.Text>

              </div>
              <div className="col-md-6 col-sm-12 col-12 my-1">
                <Form.Label className="mb-0">Morgan Stanley (MS)</Form.Label>
                <Form.Text className="text-muted">(name)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">Financial Services</Form.Label>
                <Form.Text className="text-muted">(sector)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0"> Capital Markets</Form.Label>
                <Form.Text className="text-muted">(industry)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">175.278B</Form.Label>
                <Form.Text className="text-muted">(Market Cap)</Form.Text>

              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">12.47</Form.Label>
                <Form.Text className="text-muted">(P/E Ratio)</Form.Text>

              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0"> 2.80</Form.Label>
                <Form.Text className="text-muted">(Dividend)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">NYSE</Form.Label>
                <Form.Text className="text-muted">(Exchange)</Form.Text>

              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0"> 97.35 - 100.63</Form.Label>
                <Form.Text className="text-muted">(PX_Range Today)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">97.35-105.34</Form.Label>
                <Form.Text className="text-muted">(MTD)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">57.51 - 105.95</Form.Label>
                <Form.Text className="text-muted">(YTD)</Form.Text>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-3 col-sm-6 col-6 my-1">
                <Form.Label className="mb-0">9,382,884</Form.Label>
                <Form.Text className="text-muted">(Trading volume )</Form.Text>
              </div>
            </div>
          </div>
          <div className="col-md-12 content-dotted-border col-lg-12">
            <div className="row">
              {detailsPartTwo &&
                detailsPartTwo.length &&
                detailsPartTwo.map((item, index) => (
                  <div key={index} className="col-md-6 my-1">
                    <Form.Label className="mb-0">{item.value}</Form.Label>
                    <Form.Text className="text-muted">({item.label})</Form.Text>

                  </div>
                ))}
            </div>
          </div>

          <div className="col-md-12 col-lg-12">
            <div className="row">
              {detailsPartThree &&
                detailsPartThree.length &&
                detailsPartThree.map((item, index) => (
                  <div key={index} className="col-md-6 my-1">
                    <Form.Label>{item}</Form.Label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="content-border h-100">
        <LineChart  data={ {chartSet1:chartData  } }  chatOptions={chatOptions} />
        </div>
      </div>
      <div className="col-md-12 py-15">
        <TabContent tabMenu={tabMenu} />
      </div>
    </div>
  );
};

export default EquityDetails;
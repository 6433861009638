export const bondsTableData = [
  {
    Header: "Name",
    accessor: "`name`",
    sticky: "left",
    width: 40,
  },
  {
    Header: "ISIN",
    accessor: "security",
    width: 60,
  },
  {
    Header: "Currency",
    accessor: "Currency",
    width: 30,
  },
  {
    Header: "Coupon",
    accessor: "Coupon",
    width: 30,
  },
  {
    Header: "MaturityDate",
    accessor: "MaturityDate",
    width: 40,
  },
  {
    Header: "Notional",
    accessor: "Notional",
    width: 25,
  },
  {
    Header: "Price",
    accessor: "Price",
    width: 25,
  },
  {
    Header: "Override",
    accessor: "[editButton]",
    Cell: (cellObj) => (
      <input className="form-control input-sm table-input" />
    ),
    width: 40,
  },
  {
    Header: "CurrOvrd",
    accessor: "CurrOvrd",
    width: 25,
  },
  {
    Header: "Yield",
    accessor: "Yield",
    width: 25,
  },
  {
    Header: "Dur",
    accessor: "Dur",
    width: 25,
  },
  {
    Header: "DV01",
    accessor: "DV01",
    width: 25,
  },
  {
    Header: "Basis",
    accessor: "Basis",
    width: 25,
  },
  {
    Header: "ZSpread",
    accessor: "ZSpread",
    width: 30,
  },
  {
    Header: "OASSpread",
    accessor: "OASSpread",
    width: 30,
  },
];
export const swapsTableData = [
  {
    headerName: "TRADE_ID",
    field: "Name",
    pinned: "left",
    width: 120,
    cellRenderer: "",
  },
  {
    headerName: "CSA_TYPE",
    field: "Override",
    pinned: "left",
    width: 130,
    cellRenderer: "",
  },
  {
    headerName: "CP_ID",
    field: "ISIN",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "TRADE_DATE",
    field: "Currency",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "START_DATE",
    field: "Coupon",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "END_DATE",
    field: "CouponFreq",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "PAY_REC",
    field: "DayCount",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "COUPON_RATE",
    field: "MaturityDate",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "NOMINAL",
    field: "EffectiveDate",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "NPV",
    field: "Notional",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "DV01",
    field: "Price",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "Spread01",
    field: "CurrOvrd",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
];
export const equityTableData = [
  {
    Header: "Ticker",
    accessor: "ticker",
    collapse: true,
    sticky: "left",
    width: 30,
  },
  {
    Header: "Name",
    accessor: "name",
    collapse: true,
    width: 30,
  },
  {
    Header: "%Change",
    accessor: "change",
    collapse: true,
    width: 25,
  },
  {
    Header: "Price",
    accessor: "price",
    collapse: true,
    width: 20,
  },
  {
    Header: "Bid",
    accessor: "bid",
    collapse: true,
    width: 20,
  },
  {
    Header: "Ask",
    accessor: "ask",
    collapse: true,
    width: 20,
  },

  {
    Header: "Low",
    accessor: "low",
    collapse: true,
    width: 20,
  },
  {
    Header: "High",
    accessor: "high",
    collapse: true,
    width: 20,
  },
  {
    Header: "Open",
    accessor: "open",
    collapse: true,
    width: 20,
  },
  {
    Header: "Prev Close",
    accessor: "prev",
    collapse: true,
    width: 20,
  },
];
export const FXTableData = [
  {
    Header: "Ticker",
    accessor: "ticker",
    collapse: true,
    sticky: "left",
    width: 40,
  },
  {
    Header: "%Change",
    accessor: "change",
    collapse: true,
    width: 25,
  },
  {
    Header: "Bid",
    accessor: "bid",
    collapse: true,
    width: 20,
  },
  {
    Header: "Ask",
    accessor: "ask",
    collapse: true,
    width: 20,
  },
  {
    Header: "Low",
    accessor: "low",
    collapse: true,
    width: 20,
  },
  {
    Header: "High",
    accessor: "high",
    collapse: true,
    width: 20,
  },
  {
    Header: "Open",
    accessor: "open",
    collapse: true,
    width: 20,
  },
  {
    Header: "Prev",
    accessor: "prev",
    collapse: true,
    width: 20,
  },
];
export const futuresTableData = [
  {
    headerName: "ShortName",
    field: "Name",
    pinned: "left",
    width: 120,
    cellRenderer: "",
  },
  {
    headerName: "Override",
    field: "Override",
    pinned: "left",
    width: 130,
    cellRenderer: "",
  },
  {
    headerName: "CurrOvrd",
    field: "ISIN",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "Price",
    field: "Currency",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "Bid",
    field: "Coupon",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "Ask",
    field: "CouponFreq",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "Mid",
    field: "CouponFreq",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "ZSpread",
    field: "DayCount",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "SOT",
    field: "MaturityDate",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "Yield",
    field: "Coupon",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "DV01",
    field: "CouponFreq",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "OASSprdDur",
    field: "CouponFreq",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "OASConvexity",
    field: "DayCount",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "MDur",
    field: "MaturityDate",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "FacePosn",
    field: "DayCount",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "PXChange1day",
    field: "MaturityDate",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
];

export const cashflowTableData = [
  {
    headerName: "BaseNotional",
    field: "Name",
    pinned: "left",
    width: 120,
    cellRenderer: "",
  },
  {
    headerName: "CouponAmount",
    field: "Override",
    pinned: "left",
    width: 130,
    cellRenderer: "",
  },
  {
    headerName: "CouponDCF",
    field: "ISIN",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "CouponRate",
    field: "Currency",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "EndDate",
    field: "Coupon",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "FixingDate",
    field: "CouponFreq",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "PayDate",
    field: "CouponFreq",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "PayDiscountFactor",
    field: "DayCount",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
  {
    headerName: "StartDate",
    field: "MaturityDate",
    pinned: "",
    width: 110,
    cellRenderer: "",
  },
];

export const portfolioTableData = [
  {
    headerName: "",
    field: "expand",
    pinned: "",
    width: 50,
    cellRenderer: "",
    rowGroup: true,
    hide: true,
    render: true,
  },
  {
    headerName: "Group1",
    field: "Group1",
    pinned: "",
    width: 110,
  },
  {
    headerName: "Group2",
    field: "Group2",
    pinned: "",
    width: 110,
    cellRenderer: "",
    render: false,
  },
  {
    headerName: "Ticker",
    field: "Ticker",
    pinned: "",
    width: 130,
    cellRenderer: "",
    render: false,
  },
  {
    headerName: "Security",
    pinned: "",
    width: 110,
    render: false,
    parentGroup: [
      {
        headerName: "Chart",
        pinned: "",
        width: 110,
        cellRenderer: "",
        childGroup: [
          {
            headerName: "1Month",
            field: "1Month",
            width: 110,
          },
        ],
      },
      {
        headerName: "Price",
        field: "Price",
        width: 110,
        childGroup: [
          {
            headerName: "LastPrice",
            field: "LastPrice",
            width: 80,
          },
          {
            headerName: "L/N Close",
            field: "L/N Close",
            width: 80,
          },
          {
            headerName: "Last Year",
            field: "Last Year",
            width: 80,
          },
        ],
      },
    ],
  },

  {
    headerName: "Positions",
    render: false,
    field: "Positions",
    pinned: "",
    width: 110,
    parentGroup: [
      {
        headerName: "",
        field: "",
        pinned: "",
        width: 110,
        cellRenderer: "",
        childGroup: [
          {
            headerName: "Overnight",
            field: "PositionsOvernight",
            width: 80,
          },
        ],
      },

      {
        headerName: "",
        field: "",
        pinned: "",
        width: 110,
        cellRenderer: "",
        childGroup: [
          {
            headerName: "Trading",
            field: "PositionsTrading",
            width: 80,
          },
        ],
      },

      {
        headerName: "",
        field: "",
        pinned: "",
        width: 110,
        cellRenderer: "",
        childGroup: [
          {
            headerName: "Total",
            field: "PositionsTotal",
            width: 80,
          },
        ],
      },
    ],
  },
  {
    headerName: "P & L",
    render: false,
    width: 110,
    parentGroup: [
      {
        headerName: "",
        field: "",
        pinned: "",
        width: 110,
        cellRenderer: "",
        childGroup: [
          {
            headerName: "Overnight",
            field: "PlOvernight",
            width: 110,
          },
        ],
      },

      {
        headerName: "",
        field: "",
        pinned: "",
        width: 110,
        cellRenderer: "",
        childGroup: [
          {
            headerName: "Trading",
            field: "PlTrading",
            width: 110,
          },
        ],
      },

      {
        headerName: "",
        field: "",
        pinned: "",
        width: 110,
        cellRenderer: "",
        childGroup: [
          {
            headerName: "Total",
            field: "PlTotal",
            width: 110,
          },
        ],
      },
    ],
  },
  {
    headerName: "Market Value",
    render: false,
    width: 110,
    parentGroup: [
      {
        headerName: "Fund",
        pinned: "",
        width: 110,
        childGroup: [
          {
            headerName: "Overnight",
            field: "MarketOvernight",
            width: 80,
          },
          {
            headerName: "Total",
            field: "MarketTotal",
            width: 80,
          },
          {
            headerName: "Diff",
            field: "MarketDiff",
            width: 80,
          },
        ],
      },
    ],
  },

  {
    headerName: "% Market Value",
    render: false,
    pinned: "",
    width: 110,
    parentGroup: [
      {
        headerName: "Fund",
        pinned: "",
        width: 110,
        childGroup: [
          {
            headerName: "Overnight",
            field: "NewOvernight",
            width: 80,
          },
          {
            headerName: "Total",
            field: "NewTotal",
            width: 80,
          },
          {
            headerName: "Diff",
            field: "NewDiff",
            width: 80,
          },
        ],
      },
      {
        headerName: "Index",
        field: "Index",
        pinned: "",
        width: 110,
        childGroup: [
          {
            headerName: "",
            field: "Index",
            width: 110,
          },
        ],
      },
      {
        headerName: "Fund Vs Index",
        field: "Fund",
        pinned: "",
        width: 110,
        childGroup: [
          {
            headerName: "Overnight",
            field: "FundVsIndexOvernight",
            width: 80,
          },
          {
            headerName: "Total",
            field: "FundVsIndexTotal",
            width: 80,
          },
          {
            headerName: "Diff",
            field: "FundVsIndexDiff",
            width: 80,
          },
        ],
      },
    ],
  },
];

export const EquityList = [
  {
    Header: "Name",
    accessor: "Name",
  },
  {
    Header: "Account",
    accessor: "Account",
  },
  {
    Header: "Calls",
    accessor: "Calls",
  },
  {
    Header: "Minutes",
    accessor: "Minutes",
  },
];

export const SectorList = [
  {
    Header: "Sectory",
    accessor: "sectory",
    width: 40,
  },
  {
    Header: "Industry",
    accessor: "industry",
    width: 35,
  },
  {
    Header: "Name",
    accessor: "name",
    width: 30,
  },

  {
    Header: "Issuer",
    accessor: "Issuer",
    width: 60,
  },
  {
    Header: "ID",
    accessor: "ID",
    width: 20,
  },
  {
    Header: "PX_Last",
    accessor: "PX_Last",
    width: 30,
  },
  {
    Header: "Open",
    accessor: "Open",
    width: 20,
  },
  {
    Header: "High",
    accessor: "High",
    width: 20,
  },
  {
    Header: "Low",
    accessor: "Low",
    width: 25,
  },
  {
    Header: "Close",
    accessor: "Close",
    width: 20,
  },
  {
    Header: "Prev Close",
    accessor: "PrevClose",
    width: 30,
  },
  {
    Header: "Volume",
    accessor: "Volume",
    width: 30,
  },
];

export const PortfolioList = [
  {
    Header: "Name",
    accessor: "Name",
    collapse: true,
    width:20
  },
  {
    Header: "Issuer",
    accessor: "Issuer",
    collapse: true,
    width:20

  },
  {
    Header: "ID",
    accessor: "ID",
    collapse: true,
    width:10
  },
  {
    Header: "Fund Name",
    accessor: "FundName",
    collapse: true,
    width:30

  },
  {
    Header: "Index",
    accessor: "Index",
    collapse: true,
    width:20
  },
  {
    Header: "Market Value",
    accessor: "MarketValue",
    collapse: true,
    width:30
  },
  {
    Header: "Security Market Value",
    accessor: "SecurityMarketValue",
    collapse: true,
    width:30
  },
  {
    Header: "Security weight",
    accessor: "Securityweight",
    collapse: true,
    width:30
    
  },

  {
    Header: "Low",
    accessor: "Low",
    collapse: true,
    width:20

  },
  {
    Header: "Close",
    accessor: "Close",
    collapse: true,
    width:20

  },
  {
    Header: "Prev Close",
    accessor: "Prev_Close",
    collapse: true,
    width:30

  },
  {
    Header: "Volume",
    accessor: "Volume",
    collapse: true,
    width:20

  },
];

export const EventList = [
  {
    Header: "Date",
    accessor: "Date",
    collapse: true,
    width:25
  },
  {
    Header: "Type",
    accessor: "Type",
    collapse: true,
    width:25

  },
  {
    Header: "Descriptions",
    accessor: "Descriptions",
    collapse: true,
    width:50

  },
];

import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Header from './Header'
import Dashboard from '../../containers/Dashboard/Dashboard'
import Bond from '../../containers/Bond/Bond'
import Portfolio from '../../containers/Portfolio/Portfolio'
import FundAdmin from '../../containers/FundAdmin/FundAdmin'
import Security from '../../containers/Security/Security'
import Profile from '../../containers/Profile/Profile'
import ProfileForm from '../../containers/Profile/ProfileForm/ProfileForm'
import Order from '../../containers/Order/Order'
import Trade from '../../containers/Trade/Trade'

const Layout = props => {
  const { match } = props
  const [open, setOpen] = useState(false)
  const setOpenSidebar = value => {
    setOpen(value)
  }
  document.body.style.background = '#2b3e50'

const routes = [
  { path: '/dashboard',  name: 'dashboard', component: Dashboard },
  { path: '/fund-admin',    name: 'fund-admin', component: FundAdmin },
  { path: '/profile',   name: 'profile', component: Profile },
  { path: '/profile/add',   name: '/profile/add', component: ProfileForm },
  { path: '/profile/:id',   name: '/profile/:id', component: ProfileForm },
  { path: '/TradeCapture/FixedIncomeBond',   name: '/TradeCapture/FixedIncomeBond', component:  Bond },
  { path: '/portfolio',  name: 'portfolio', component: Portfolio },
  { path: '/security',    name: 'security', component: Security },
  { path: '/order',   name: 'order', component: Order },
  { path: '/trade',   name: 'trade', component: Trade }
]

  return (
    <>
      <Header action={data => setOpenSidebar(data)} />
      <div className={open ? 'layout sidebar-close h-100' : 'layout sidebar-open h-100'}>
      <div className="container-fluid py-3">
        <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => 
                    <route.component {...props} />
                  } />
              )
            })}
          </Switch>
      </div>
      </div>
    </>
  )
}

export default Layout

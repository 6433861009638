import { useState, useEffect, useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import { bondsTableData } from "../../../utilities/tablefields";
import { DataTable } from "../../../components/HigherOrderComponent/DataTable";
import httpService from "../../../common/http.service";
import { WebSocketContext } from "../../../WebSocket";
const BondTable = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { model } = props;
  const { ws, connected } = useContext(WebSocketContext);


  const viewModal = () => {
    props.viewTableModal("BondTable");
  };

  const config = {
    tableCard: {
      classes: ["h-inherit"]
    },
    tableWrapper: {
      classes: ["h-100"]
    }
  }

 

  useEffect(() => {
    if (connected && ws) {
      console.log("connected");
    }
  }, [connected])

  return (
    <>
      <div className="panel card panel-default mb-2 h-100">
        <div className="card-body px-2 py-2 h-100">
          <div className="row ">
            <div className="col-md-12">
              <div className="table-title">Bonds</div>
              <div className="pull-right table-panel-header">
              
                <div className="lastupdate">Last Update :</div>
                <span className="lastupdateHead">00:00:00</span>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
                <Button
                  variant="default"
                  className="color-white button-padding"
                >
                  <i className="fa fa-money"></i>Price
                </Button>
                {model && (
                  <Button
                    onClick={() => viewModal()}
                    variant="default"
                    className="color-white button-padding"
                  >
                    <i className="fa fa-window-maximize"></i>
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="row bond-table h-90">
            <div className="col-md-12 h-100">
              <DataTable
                pagination={true}
                config={config}
                columnData={bondsTableData}
                data={data}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BondTable;

import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DatePicker from "react-datepicker";
import { DataTable } from "../../components/HigherOrderComponent/DataTable";

const Portfolio = () => {
  const columnData = [
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <i  className="fa fa-chevron-down fa-xs"></i>
          ) : (
            <i className="fa fa-chevron-right fa-xs"></i>
          )}
        </span>
      ),
      width:20
    },
    {
      Header: " ",
      columns: [
        {
          Header: "Group 1",
          accessor: "group1",
          width:60
        },
        {
          Header: "Group 2",
          accessor: "group2",
          width:60

        },
        {
          Header: "ticker",
          accessor: "ticker",
          width:60

        },
      ],
    },
    {
      Header: "Security",
      columns: [
        {
          Header: "Chart",
          columns: [{ Header: "1Month", accessor: "month1" ,  width:60}],
        },
        {
          Header: "Price",
          columns: [
            { Header: "LastPrice", accessor: "lastPrice"  ,  width:60},
            { Header: "L/N", accessor: "lnclone" ,  width:60},
            { Header: "Last Year", accessor: "lastYear" ,  width:60},
          ],
        },
      ],
    },
    {
      Header: "Postions",
      columns: [
        {
          Header: "Overnight",
          accessor: "postions_overnight",
          width:60
        },
        {
          Header: "Trading",
          accessor: "postions_trading",
          width:60
        },
        {
          Header: "Total",
          accessor: "postions_total",
          width:60
        },
      ],
    },
    {
      Header: "P&L",
      columns: [
        {
          Header: "Overnight",
          accessor: "pl_overnight",
          width:60
        },
        {
          Header: "Trading",
          accessor: "pl_trading",
          width:60
        },
        {
          Header: "Total",
          accessor: "pl_total",
          width:60
        },
      ],
    },
  ];

  const subColumnData = [
    {
      Header: "Ticker",
      accessor: "Ticker",
      width:60
    },
    {
      Header: "Pe Ration",
      accessor: "PeRation",
      width:60
    },
    {
      Header: "Market Cap",
      accessor: "MarketCap",
      width:60
    },
    {
      Header: "Volumn",
      accessor: "Volumn",
      width:60
    },
  ];

  const [startDate, setStartDate] = useState(new Date());

  const subData = [
    {
      Ticker: "10.00",
      PeRation: "20.00",
      MarketCap: "50.4",
      Volumn: "60.5",
    },
    {
      Ticker: "55.2",
      PeRation: "40.55 ",
      MarketCap: "70.23",
      Volumn: "10.00",
    },
    {
      Ticker: "21.55",
      PeRation: "0.02",
      MarketCap: "55.69",
      Volumn: "12.3",
    },
    {
      Ticker: "10.5",
      PeRation: "55.3",
      MarketCap: "60.2",
      Volumn: "0.02",
    },
  ];

  const data = [
    {
      group1: "G1",
      group2: "G2",
      ticker: "MS",
      month1: "53.20",
      lastPrice: "10.2",
      lnclone: "55.00",
      lastYear: "0.005",
      postions_overnight: "1",
      postions_trading: "10,1000",
      postions_total: "100000",
      pl_overnight: "500",
      pl_trading: "300",
      pl_total: "20",
    },
    
  ];
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <DataTable
              columnData={subColumnData}
              data={subData}
              pagination={false}
            />
    ),
    []
  );

  return (
    <div className="row-tables  w-100">
      <div className="panel panel-default h-100">
        <div className="panel-body paneldecoration h-100">
          <div className="row pb-0">
            <div className="col-md-12">
              <div className="table-title">Portfolio</div>
              <div className="table-panel-header">
                <div>
                  <label className="mb-0 mr-2">Portfolio:</label>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Please Choose"
                    variant="default"
                  >
                    <Dropdown.Item eventKey="option-1">Action</Dropdown.Item>
                    <Dropdown.Item eventKey="option-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="option-3">
                      Something else
                    </Dropdown.Item>
                  </DropdownButton>
                </div>

                <div className="mx-3">
                  <label className="mb-0 mr-2">Fund:</label>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Please Choose"
                    variant="default"
                  >
                    <Dropdown.Item eventKey="option-1">Action</Dropdown.Item>
                    <Dropdown.Item eventKey="option-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="option-3">
                      Something else
                    </Dropdown.Item>
                  </DropdownButton>
                </div>

                <div >
                <label className="mb-0 ">Date:</label>
<DatePicker
  selected={startDate}
  onChange={(date) => setStartDate(date)}
/>
                </div>
              </div>
            </div>
          </div>
          <div className="h-100">
            <DataTable
              columnData={columnData}
              data={data}
              pagination={true}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
